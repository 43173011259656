import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared-modules/services/auth.service';
import { RenewPlanService } from '../renew-plan.service';
import moment from 'moment';
@Component({
  selector: 'fyt-renew-plan',
  templateUrl: './renew-plan.component.html',
  styleUrls: ['./renew-plan.component.css']
})
export class RenewPlanComponent implements OnInit {
  @Input() inputDataObj: any;
  @Output() onActon = new EventEmitter<any>()
  renewPlanData: any;
  isbuyPlan: boolean = false;
  onlinePayFlag: boolean;
  payOptionsFlag: boolean;
  upgradePlanFlag: boolean;
  payBalanceFlag: boolean = false;
  flag: boolean = false;
  payAndActiveFlag: boolean;
  halfPackageCost: number;
  errorFlag: boolean;
  payOptionsBtnFlagR: boolean;
  passData: any;
  loader: boolean;
  offData: any;
  paydata: any;
  url: any;
  isOffline: boolean;
  offlineObj: any = {};
  paymentMode: any = ['NEFT', 'CHEQUE'];
  fileObj: any={};
  fakeImgPath: any;
  isOffline2: boolean;
  backUrl:any;
  paymentpostObj: any = {};
  newpayBalanceFlag: boolean = false;
  paidAmount: any;
  pendingAmount: any;
 
  constructor(
    public router: Router,
    private authService: AuthService,
    public renewPlanService: RenewPlanService,
    private snackbar: MatSnackBar
  ) { }


  ngOnInit(): void {
    this.renewPlan()
    this.backUrl = '/gym/dashboard';
  }
  renewPlan() {
    this.isbuyPlan = true;
    this.payAndActiveFlag = true;
    this.payBalanceFlag = true;
    this.onlinePayFlag = true;
    this.payOptionsFlag = true;
    this.getPlanData();
  }
//  
  checkPartialPayAmount() {
    this.halfPackageCost = ((this.renewPlanData.userCost / 3) > 5000) ? (this.renewPlanData.userCost / 3) : 5000;
    if (this.renewPlanData.pendingAmount >= this.halfPackageCost) {
      this.errorFlag = false
      this.payOptionsFlag = true;
    } else {
      this.errorFlag = true;
      this.payOptionsFlag = false;
    }
  }

  closeDialog() {
    this.onlinePayFlag = false
  }

  closeDialog1() {
    this.payBalanceFlag = false;
    // this.payOptionsBtnFlag = false;
    this.payAndActiveFlag = false;
    this.errorFlag = false;
    this.isbuyPlan = false
  }
  payPartialFunctionRenewPlan(data) {
    this.payOptionsBtnFlagR = true;
    this.loader = false;
    this.passData = data;
    this.buyplan(this.passData);
  }
  buyplan(data) {
    console.log("buyplan--data", this.renewPlanData);
    this.loader = true;
    this.offData = this.renewPlanData;
    let postObj = {};
    postObj = {
      planId: this.renewPlanData.planId,
      // validity: data.validity,
      packageCost: this.renewPlanData.pendingAmount ? this.renewPlanData.pendingAmount : this.renewPlanData.appCost,
      // promoName: promoName,
      planType: this.renewPlanData.planType,
      planSubType: this.renewPlanData.planSubType,
      durationType: this.renewPlanData.durationType,
      paidAmount: this.renewPlanData.pendingAmount ? this.renewPlanData.pendingAmount : this.renewPlanData.appCost,
      actualPackageCost: this.renewPlanData.appCost,
      // sms: this.renewPlanData.sms,
      packageId: this.renewPlanData.packageId,
      // adminUserId: this.renewPlanData.adminUserId ? this.renewPlanData.adminUserId : this.adminUserId,
      centreId: this.renewPlanData.centreId ? this.renewPlanData.centreId : null,
      centreName: this.renewPlanData.centreName ? this.renewPlanData.centreName : null,
      planStartDate: this.renewPlanData.startDate ? this.renewPlanData.startDate : null
    }
    this.renewPlanService.buyPlansDetails(postObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.paydata = res.paytmData;
        this.url = res.url;
        console.log("this.paydata", this.url, this.paydata, this.payOptionsBtnFlagR);
      }
      //  else if (res.statusCode == "002") {
      //   // this.snackbar.open(res.Message, 'Please Enter Valid Promo Code', { duration: 3000 });
      // }
      this.loader = false;
    });
  }

  backToPlan() {
    this.isOffline2 = false;
    this.isOffline = false;
  }
  onSubmit() {
    this.loader = false;
  }
  onRenewOffline() {
    this.isOffline = true;
    // this.isPlansList = false;
    this.isbuyPlan = false
    this.payAndActiveFlag = false;
  }

  clearFile() {
    delete this.fileObj.uploadFile;
    delete this.offlineObj.file;
    this.fakeImgPath = undefined;
  }
  handleFileInput(event) {
    // console.log("handleFileInput");
    
    this.loader = true;
    if (event.target.files.length > 0) {
      this.fileObj.uploadFile = event.target.files[0];
      if (event.target.files[0].size <= 5 * 1024 * 1024) {
        let fileType = this.fileObj.uploadFile;
        if (fileType && fileType.name) {
          fileType = fileType.name.toString().toLowerCase();
          fileType = fileType.split(".");
          const type = fileType;
          if (
            type &&
            type.length > 0 &&
            (type[type.length - 1] === "jpg" ||
              type[type.length - 1] === "png" ||
              type[type.length - 1] === "jpeg")
          ) {
            this.offlineObj.file = event.target.files[0];
            this.previewImg(event);
          } else {
            this.showErrorMessage(
              `Sorry, 
              '${this.fileObj.uploadFile.name}' 
              is invalid, allowed extensions are: (.jpg, .jpeg, .png) files`,
              "Got it!"
            );
          }
        }
      } else {
        this.showErrorMessage("File size should not exceed 5MB", "Got it");
      }
      this.loader = false;
    }
  }

  previewImg(data) {
    this.fakeImgPath = undefined;
    if (data && data.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(data.target.files[0]);
      reader.onload = (data) => {
        this.fakeImgPath = data.target["result"];
      };
    }
  }
  showErrorMessage(msg1, msg2) {
    this.snackbar.open(msg1, msg2, { duration: 6000 });
  }
  offlineSubmit() {
    this.loader = true;
    this.offlineObj.ORDER_ID = this.paydata.ORDER_ID;
    this.offlineObj.paidAmount = this.paymentpostObj.paidAmount;
    this.offlineObj.adminPlanId = this.renewPlanData.adminPlanId;
    this.offlineObj.isFirstPayment = true;
    if (this.offlineObj.chequeIssueDate) this.offlineObj.chequeIssueDate = moment(this.offlineObj.chequeIssueDate).startOf('day');
    if (this.renewPlanData.adminUserId) {
      this.offlineObj.adminUserId = this.renewPlanData.adminUserId,
        this.offlineObj.centreId = this.renewPlanData.centreId,
        this.offlineObj.centreName = this.renewPlanData.centreName
    }
    this.renewPlanService.offlinePayment(this.offlineObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.loader = false;
        this.isOffline2 = false;
        if (this.renewPlanData.adminUserId) this.router.navigate(['/admin/reg/profile/' + this.renewPlanData.adminUserId])
        else this.router.navigate(['gym/dashboard/']);
      } else if (res.statusCode == "002") {
        this.loader = false;
      }
    });
  }

  offlineEntry() {
    if (this.newpayBalanceFlag) this.partialOfflineSubmit()
    else this.offlineSubmit()
  }

  partialOfflineSubmit() {
    this.loader = true;
    this.offlineObj.ORDER_ID = this.paydata.ORDER_ID;
    this.offlineObj.paidAmount = this.paymentpostObj.paidAmount;
    this.offlineObj.adminPlanId = this.renewPlanData.adminPlanId;
    this.offlineObj.isFirstPayment = true;
    if (this.offlineObj.chequeIssueDate) this.offlineObj.chequeIssueDate = moment(this.offlineObj.chequeIssueDate).startOf('day');
    if (this.renewPlanData.adminUserId) {
      this.offlineObj.adminUserId = this.renewPlanData.adminUserId,
        this.offlineObj.centreId = this.renewPlanData.centreId,
        this.offlineObj.centreName = this.renewPlanData.centreName
    }
    this.renewPlanService.partialOfflinePayment(this.offlineObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.loader = false;
        this.isOffline2 = false;
        if (this.renewPlanData.adminUserId) this.router.navigate(['/admin/reg/profile/' + this.renewPlanData.adminUserId])
        else this.router.navigate(['gym/dashboard/']);
      } else if (res.statusCode == "002") {
        this.loader = false;
      }
    });
  }

  renewofflineSubmit() {
    this.loader = true;
    // console.log("ifOFF");
    this.offlineObj.ORDER_ID = this.paydata.ORDER_ID;
    this.offlineObj.paidAmount = this.paymentpostObj.paidAmount;
    this.offlineObj.adminPlanId = this.renewPlanData.adminPlanId;
    if (this.offlineObj.chequeIssueDate) this.offlineObj.chequeIssueDate = moment(this.offlineObj.chequeIssueDate).startOf('day');
    if (this.renewPlanData.adminUserId) {
      this.offlineObj.adminUserId = this.renewPlanData.adminUserId,
        this.offlineObj.centreId = this.renewPlanData.centreId,
        this.offlineObj.centreName = this.renewPlanData.centreName
    }
    this.renewPlanService.renewOfflinePayment(this.offlineObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.loader = false;
        this.isOffline = false;
        // console.log("this.planData.adminUserId", this.planData.adminUserId);
        if (this.renewPlanData.adminUserId) this.router.navigate(['/admin/reg/profile/' + this.renewPlanData.adminUserId])
        else this.router.navigate(['gym/dashboard/']);
      } else if (res.statusCode == "002") {
        this.loader = false;
      }
    });
  }

  
  getPlanData() {
    this.renewPlanService.getLastPlanData({}).subscribe((res) => {
      if (res.statusCode == "001") {
        this.renewPlanData = res.data
        console.log(" this.renewPlanData", this.renewPlanData);
        
      }
    });
  }

  
}
