import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CameraRoutingModule } from './camera-routing.module';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';




@NgModule({
  declarations: [CameraComponent],
  imports: [
    CommonModule,
    CameraRoutingModule,
    WebcamModule,
    FormsModule,
    AngularMaterialModule
  ],
  exports: [CameraComponent]
})
export class CameraModule { }
