import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { GlobeSearchService } from '../globe-search.service';
import { Router } from '@angular/router';
import openSocket from 'socket.io-client';

@Component({
    selector: 'fyt-globe-search',
    templateUrl: 'globe.component.html',
    styleUrls: ['globe.component.css']
})

export class GlobeSearchComponent implements OnInit, OnChanges {
    @Input() searchInput: any;
    @Output() onAction = new EventEmitter<any>();
    postObj: any = {};
    searchResponse: any = [];
    message: any;
    plansDetails: any;
    loader: boolean;


    minlength: any = 10;
    maxlength: any = 10;


    constructor(
        private router: Router,
        private globeSearchService: GlobeSearchService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(): void {
        if ((['', null, undefined]).includes(this.searchInput)) {
            // return;
        } if (this.searchInput && !this.searchInput.phoneNumber) {
            this.postObj.phoneNo = this.searchInput;
            this.globeSearch();
        }
        // if (this.searchInput.phoneNumber) {
        //     this.postObj.phoneNo = this.searchInput.phoneNumber;
        //     this.globeSearchAnother();
        // }
    }

    onCancel() {
        this.onAction.emit();
    }

    globeSearch() {
        if (this.postObj && !this.postObj.phoneNo) {
            return
        }
        this.loader = true;
        this.globeSearchService.globeSearch(this.postObj).subscribe(res => {
            this.searchResponse = [];


            if (res && res.data) {
                if (res.data && res.data.data) {
                    if (res.data.data.length > 0) {
                        this.searchResponse = res.data.data;
                        console.log("this.searchResponse",this.searchResponse);
                        this.message = res.data.message;
                    }
                }
            }
            this.loader = false;
        });
    }


    globeSearchAnother() {
        if (this.postObj && !this.postObj.phoneNo) {
            return
        }
        this.loader = true;
        this.globeSearchService.globeSearchSecond(this.postObj).subscribe(res => {
            this.searchResponse = [];
            if (res && res.data) {
                if (res.data && res.data.data) {
                    if (res.data.data.length > 0) {
                        this.searchResponse = res.data.data;
                        this.message = res.data.message;

                    }
                }
            }
            this.loader = false;
        });
    }

    goToDetails(page) {
        // console.log('page', page);
        // /gym/enquiry/add-enquiry/1212121299
        // gym/members/add-member/603f500f74de1fe8164b38f4
        this.router.navigate([page]);
        // window.open('/#'+ page);
        this.onCancel();
    }

    goAddMember() {
        let page = '/gym/members/add-member/' + this.postObj.phoneNo;
        this.router.navigate([page]);
        this.onCancel();
    }

    dynamicLength(searchInput1) {
        if (isNaN(this.postObj.phoneNo)) {
            // console.log('isNaN(this.searchInput)---1', isNaN(this.searchInput));
            this.minlength = 1;
            this.maxlength = 25;
        } else {
            // console.log('isNaN(this.searchInput)---2', isNaN(this.searchInput));
            this.minlength = 10;
            this.maxlength = 10;
        }
    }

    openProfile(page) {
        window.open('/#' + page);
    }
}
