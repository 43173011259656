import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalApiService } from '../../shared-modules/global-api.service';
import { AuthService } from '../../shared-modules/services/auth.service'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SideNavService {
  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService) { }

  // for sidenav from database
  getsideNav(): Observable<any> {
    return this.http.get(this.globalApiService.getApiUrl() + '/structure/getSideNav')
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  updateDefaultBranch(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/updateDefaultSetting', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  getCentreBrandData(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/getCentreBranchList', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  openDoor(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/branch/openDoor', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }


  smsLimitFunction(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/getNotification', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  accessSettings(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/mySettings', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  // check door connection
  doorConnection(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/device/checkDeviceLive', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  // check access
  checkAccess(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/checkAccess', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  // check access
  getUpdates(): Observable<any> {
    return this.http.get(this.globalApiService.getApiUrl() + '/adminSales/gymNewUpdates')
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}