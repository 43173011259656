import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubExpiredPopupComponent } from './sub-expired-popup/sub-expired-popup.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';
import { SubExpiredService } from './sub-expired.service';
import { DashboardModule } from '../../dashboard/dashboard.module';
// import { RenewPlanComponent } from './renew-plan/renew-plan.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RenewPlanModule } from '../../renew-plan/renew-plan.module';


@NgModule({
  declarations: [SubExpiredPopupComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatMomentDateModule,
    RenewPlanModule
  ],
  exports: [
    SubExpiredPopupComponent,
    // MemberComponent
  ],
  providers: [SubExpiredService]
})
export class SubExpiredModule { }
