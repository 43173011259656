import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'fyt-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.css']
})
export class NotifyPopupComponent implements OnInit {
  @Input() notifyInput: any;
  @Output() onAction = new EventEmitter<any>();
  displayData:any;

  constructor() { }

  ngOnInit(): void {
    // console.log('notifyInput', this.notifyInput);
    this.displayData = this.notifyInput;
  }

  closeDialog(flag) {
    this.onAction.emit({ flag: flag, page: 'renewMembership' })
}
}
