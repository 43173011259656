import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignStaffService } from '../assign-staff.service';

@Component({
  selector: 'fyt-assign-staff',
  templateUrl: './assign-staff.component.html',
  styleUrls: ['./assign-staff.component.css']
})
export class AssignStaffComponent implements OnInit {

  @Input() InputObj: any;
  @Output() onActon = new EventEmitter<any>();

  trainerSelectFlag: boolean = false;
  enquiry_id: any[];
  selectStaffObj: any = {};
  showSendButton: boolean = false;
  staffList: any;

  constructor(
    private assignStaffService: AssignStaffService
  ) { }

  ngOnInit(): void {
    console.log("InputObj", this.InputObj);
    this.getStaffList()
  }

  assignStaff() {
    let postObj = {
      _id: this.InputObj.id,
      adminUserId: this.selectStaffObj.adminUserId,
      staffName: this.selectStaffObj.name,
      enqName: this.InputObj?.fullName
    }
    if (this.InputObj?.type === 'member') {
      this.assignStaffService.assignMember(postObj).subscribe((res) => {
        if (res.statusCode == "001") {
          this.closeDialog('cancel')
        }
      })
    } else {
      this.assignStaffService.assignEnquiry(postObj).subscribe((res) => {
        if (res.statusCode == "001") {
          this.closeDialog('cancel')
        }
      })
    }

  }

  assignStaff1(data: any) {
    this.selectStaffObj = data
  }

  getStaffList() {
    this.assignStaffService.getStaffList().subscribe((res) => {
      if (res.statusCode == "001") {
        if (res.data) {
          this.staffList = res.data
        }
      }
    })
  }

  closeDialog(flag) {
    this.onActon.emit({ flag: flag });
  }

}
