import { Component, OnInit } from '@angular/core';
import { AdsUploadService } from '../ads-upload.service';

@Component({
  selector: 'fyt-ads-upload',
  templateUrl: './ads-upload.component.html',
  styleUrls: ['./ads-upload.component.css']
})
export class AdsUploadComponent implements OnInit {

  public id: string;
  branchData: any;
  loader: boolean;
  loader3: boolean = false;
  imageBox: any = {};
  addImageInput: any = {};
  qr: any;

  uploadImageObj: any = {};
  uploadObj: any = {};
  fakeImgPath: any;
  passObj: any = {}
  progLength: any;
  gallery: any;
  galleryArray: any = [];
  galleryArray1: any = [];
  galleryArray2: any = [];
  getImgdata: any;
  actions: any;
  promotionObj: any = {};
  settingsObj: any = {
    automatedSms: false,
    showTermsCondition: false
  };
  termsObj: any = {};
  inputData: any;
  centerBranchData: any;
  profileDetails: any = {};
  generalsettings: any;
  settingsData: any;
  esslData: any;
  postObj: any = {
    page: 'branchFeedback'
  };

  newBasicInfoFlag: boolean = true;
  newTimingFlag: boolean = false;
  newServicesFlag: boolean = false;
  newImagesFlag: boolean = false;
  newAddressFlag: boolean = false;
  newSettingsFlag: boolean = false;
  newPrintQrFlag: boolean = false;
  newBiometricFlag: boolean = false;
  newFeedbackFlag: boolean = false;
  resetBioFlag: boolean = false;
  settingFlag: any;
  esslSerialNo: any;
  serialNoObj: any = { serialNo0: "Click to add serial number", serialNo1: "Click to add serial number", serialNo2: "Click to add serial number" };
  serialFlag: boolean = true;
  bioData: any = { "0": {}, "1": {}, "2": {} };
  updateLogoFlag: boolean;
  branchId: string;
  replyReviewFlag: boolean = false;
  reviewData: any;
  replyToReview: any;
  constructor(
    private adsUploadService: AdsUploadService
  ) {
    this.actions = [
      { name: 'delete' }
    ]
  }

  ngOnInit(): void {
    this.getfirstSider("firstSider", "dashboard");
    this.getfirstSider("secondSider", "dashboard");
    this.getfirstSider("thirdSider", "dashboard");
  }

  getfirstSider(title, adsShowOn) {
    this.adsUploadService.getAdsImages({ title, adsShowOn }).subscribe(res => {
      if (res.statusCode == "001") {
        if (title == 'firstSider') this.galleryArray = res.Data.imageUrls
        if (title == 'secondSider') this.galleryArray1 = res.Data.imageUrls
        if (title == 'thirdSider') this.galleryArray2 = res.Data.imageUrls
      }
    })
  }

  getBranchDetails() {
    this.adsUploadService.branchIdDetails(this.id).subscribe(res => {
      if (res.Status == "Success") {
        this.branchData = res.data;
        console.log("branch data", this.branchData);

        // this.galleryArray = this.branchData.gallery;
        if (res && res.data && res.data.bioData[0] && res.data.bioData[0].esslSrNo) {
          this.serialNoObj.serialNo0 = res.data.bioData[0].esslSrNo.replace(/\s+/g, "");
          // console.log("this.serialNoObj.serialNo0",this.serialNoObj.serialNo0);
          this.bioData[0] = res.data.bioData[0];
          if (res.data.bioData[1]) {
            this.serialNoObj.serialNo1 = res.data.bioData[1].esslSrNo.replace(/\s+/g, "");
            // console.log("this.serialNoObj.serialNo1",this.serialNoObj.serialNo1);

            this.bioData[1] = res.data.bioData[1];
          }
          if (res.data.bioData[2]) {
            this.serialNoObj.serialNo2 = res.data.bioData[2].esslSrNo.replace(/\s+/g, "");
            // console.log("this.serialNoObj.serialNo2",this.serialNoObj.serialNo2);

            this.bioData[2] = res.data.bioData[2];
          }
        }
      }
    })
  }

  upProgressFile() {
    this.loader = true;
    this.uploadObj.adsShowOn = 'dashboard';
    this.adsUploadService.uploadAds(this.uploadObj).subscribe(res => {
      if (res.statusCode === "001") {
        this.getfirstSider(this.uploadObj.title, this.uploadObj.adsShowOn);
        this.clearProgressFile();
        this.loader = false;
      }
    });
  }

  replaceAds() {
    this.loader = true;
    this.uploadObj.adsShowOn = 'dashboard';
    this.adsUploadService.replaceAdsImage(this.uploadObj).subscribe(res => {
      if (res.statusCode === "001") {
        this.getfirstSider(this.uploadObj.title, this.uploadObj.adsShowOn);
        this.clearProgressFile();
        this.loader = false;
      }
    });
  }

  clearProgressFile() {
    delete this.uploadImageObj[this.uploadObj.title];
    this.uploadObj = {};
    this.fakeImgPath = undefined
  }

  handleFileProgressInput(event, title) {
    this.loader = true;
    if (event.target.files.length > 0) {
      this.uploadObj.title = title
      this.uploadImageObj[title] = event.target.files[0];
      if (event.target.files[0].size <= 5 * 1024 * 1024) {
        let fileType = this.uploadImageObj[title];
        if (fileType && fileType.name) {
          fileType = fileType.name.toString().toLowerCase();
          fileType = fileType.split('.');
          const type = fileType;
          if (type && type.length > 0 &&
            (type[type.length - 1] === 'jpg' ||
              type[type.length - 1] === 'png' ||
              type[type.length - 1] === 'jpeg')) {
            this.uploadObj.file = event.target.files[0];
            this.previewImg(event);
          } else {
            this.showErrorMessage(`Sorry, 
            '${this.uploadImageObj[title].name}' 
            is invalid, allowed extensions are: (.jpg, .jpeg, .png) files`, 'Got it!');
          }
        }
      } else {
        this.showErrorMessage('File size should not exceed 5MB', 'Got it');
      }
      this.loader = false;
    }
  }

  onAction(action, fileId) {
    this.loader = true;
    if (action === 'delete') {
      this.adsUploadService.imgOperations({ title: "firstSider", adsShowOn: 'dashboard', imageId: fileId }).subscribe(res => {
        if (res.statusCode === "001") {
          this.getfirstSider("firstSider", "dashboard");
          this.loader = false;
        }
      });
    }

  }

  previewImg(data) {
    this.fakeImgPath = undefined;
    if (data && data.target && data.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(data.target.files[0]);
      reader.onload = (data) => {
        this.fakeImgPath = data.target['result'];
      }
    } else {
      this.fakeImgPath = data.imageAsDataUrl;
    }
  }

  showErrorMessage(msg1, msg2) {
  }

}
