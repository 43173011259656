import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fyt-package-popup',
  templateUrl: './package-popup.component.html',
  styleUrls: ['./package-popup.component.css']
})
export class PackagePopupComponent implements OnInit {

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  goToSubscribePlan(){
    // this.router.navigate(['register/pay'])
    this.router.navigate(['services/sms-plan/fyt-services'])
  }
}
