import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssignStaffService {

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService
  ) { }

  assignEnquiry(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/enquiry/assign', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  assignMember(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/user/assign', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  // staff list
  getStaffList(): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/structure/getStaffList', {}).pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}
