import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdsUploadService {

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService
  ) { }

  uploadBranchImages(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
      formData.append('files', postObj.file);
    }
    formData.append('data', JSON.stringify(postObj));

    return this.http.post(this.globalApiService.getApiUrl() + '/branch/update', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  uploadAds(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
      formData.append('file', postObj.file);
    }
    formData.append('data', JSON.stringify(postObj));

    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/updatesAds', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  replaceAdsImage(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
      formData.append('file', postObj.file);
    }
    formData.append('data', JSON.stringify(postObj));

    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/replaceAdsImage', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  imgOperations(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/deleteAdsImage', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  branchIdDetails(id): Observable<any> {
    return this.http.get(this.globalApiService.getApiUrl() + '/branch/' + id)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  getAdsImages(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminUser/getsAdsImage', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}
