import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminBlogService } from '../admin-blog.service';
import { AddBlogService } from '../../add-blog/add-blog.service';

@Component({
  selector: 'fyt-admin-blog',
  templateUrl: './admin-blog.component.html',
  styleUrls: ['./admin-blog.component.css']
})
export class AdminBlogComponent implements OnInit {

  // blogs: any[] = [];
  blogs: any[];

  constructor(
    private blogService: AdminBlogService,
    private addBlogService: AddBlogService
  ) { }

  ngOnInit(): void {
    this.fetchBlogs();
    this.blogs = [this.addBlogService.blogData];
  }

  // getForm(blogName: string) {
  // this.blogService.getBlogs().subscribe(res => {
  //     var Dynamicforms = res.payload.form;
  //     this.apiPath = res.payload.apiPath;
  //     this.getformExtraKey = res.payload?.getformExtraKey;
  //     this.isAPISecure = res.payload.isAPISecure;
  //     this.isUpdateForm = res.payload.isUpdateForm;
  //     this.getDataApi = res.payload?.getDataApi;
  //     this.formName = res.payload.formName;
  //     this.redirectTo = res.payload.redirectTo;
  //     this.formClass = res.payload.formClass;
  //   });
  // }
  
  fetchBlogs() {
    this.blogService.getBlogs().subscribe(
      (data: any) => {
        this.blogs = data.payload;
      },
      error => {
        console.error('Error fetching blogs: ', error);
      }
    );
  }

}
