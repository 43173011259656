import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import openSocket from 'socket.io-client';
import { GlobeNotifyService } from '../globe-notify.service';


@Component({
  selector: 'fyt-globe-notify',
  templateUrl: './globe-notify.component.html',
  styleUrls: ['./globe-notify.component.css']
})
export class GlobeNotifyComponent implements OnInit, OnChanges {
  @Input() notifyInput: any;
  @Output() onAction = new EventEmitter<any>();
  planData: any = {};
  todayDate: any;
  dateFlag : boolean = false;
  notifyFlag : boolean = false;
  constructor(
    private router: Router,
    private globeNotifyService: GlobeNotifyService
  ) { }

  ngOnInit() {
    if (this.notifyInput.plans[0]) this.planData = this.notifyInput.plans[0];
    this.todayDate = new Date();
    // console.log("this.startDate",this.todayDate);

    
  }

  ngOnChanges(): void {
    this.planData = {
      billingId: "",
      subscriptionDetails: "",
      pendingAmount: "",
      link: "",
      status: 'Unautorised',
      startDate: "",
      endDate: ""
    };
    if (this.notifyInput.plans[0]) this.planData = this.notifyInput.plans[0];

    if(new Date(this.planData.dueDate) > new Date()){
      this.dateFlag = true;
      // console.log("this.ifffff",this.dateFlag);
    }
    else{
      this.dateFlag = false;
      // console.log("this.elssss",this.dateFlag);
    }
  }

  closeDialog(flag) {
    this.onAction.emit({ flag: flag, page: 'notifyPopupPage' });
  }

  goMemberProfile(userData) {
    this.router.navigate(['/gym/members/mem/' + userData.userId]);
    this.closeDialog('cancel');
  }

}
