import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AddBlogService {

  blogData: any;

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService
  ) { }

  createBlog(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.files) {
      console.log("files", postObj.files);
      for (let i = 0; i < postObj.files.length; i++) {
        formData.append('files', postObj.files[i]);
      }
    }
    formData.append('data', JSON.stringify(postObj));
    return this.http.post(this.globalApiService.getApiUrl() + '/blog/addpreviewBlog', formData).pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  uploadBlog(file): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(this.globalApiService.getApiUrl() + '/blog/uploadImage', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  addBlogs(postobj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/blog/addpreviewBlognew', postobj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}
