import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  // Function to determine whether user is authorized to view route
  canActivate(router1: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.authService.loggedIn(state.url)) {
      this.router.navigate(['']);
      return false;
    } else {
      return true;
    }
  }
}
