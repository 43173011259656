import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../shared-modules/services/auth.service';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationCancel
} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { SocketService } from 'src/app/shared-modules/services/chat.service';
import { Event } from '../../models/side-nav.data';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'fyt-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})

export class SidebarComponent implements OnDestroy, AfterViewInit {
  @ViewChild('snav', { static: false }) snav: MatSidenav;
  mobileQuery: MediaQueryList;
  dynamicNav: any = [];
  private _mobileQueryListener: () => void;
  iconUrl = '../../../assets/icons/svgIcons/red-0';
  expanded: boolean;
  userData: any;
  isNavLoading: boolean;
  loaderType: string;
  userType: any;
  userName: any;
  showSearch: any = {};
  searchInput: any = {};
  ioConnection: any;
  private readonly notifier: NotifierService;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sidebarService: SidebarService,
    private authService: AuthService,
    public socketService: SocketService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    public notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // if ((['/members', '/enquiry', '/staff', '/accounts', '/branches']).includes(this.router.url)) {
    //   this.loaderType = 'custom';
    // } else {
    //   this.loaderType = 'custom';
    // }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isNavLoading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.isNavLoading = false;
        }
      });
  }

  ngOnInit() {
    this.refreshData();
    this.userData = this.authService.loadUserData();
    this.userType = JSON.parse(this.userData).role;
    if (JSON.parse(this.userData).fullname) {
      this.userName = JSON.parse(this.userData).fullname;
    } else {
      this.userName = 'SUPER ADMIN';
    }
    this.onSocketInit();
  }


  // for socket
  onSocketInit() {
    this.socketService.initializeSocketIO();
    this.ioConnection = this.socketService.getMessages().subscribe((message) => { this.getDataForPopup(message); });
    // on connect and disconnetct
    this.socketService.onEvent(Event.CONNECT).subscribe(() => { this.emmitFun(); });
    this.socketService.onEvent(Event.DISCONNECT).subscribe(() => { });
    this.socketService.getNotifications().subscribe((data) => { this.toShowNotification(data.type, data.message) });
  }

  toShowNotification(type, message) {
    console.log({ type, message });
    this.notifier.show({
      type: type,
      message: message
    });
  }

  getDataForPopup(data) {
  }

  emmitFun() {
    this.socketService.notificationConnection({
      from: this.userName,
      content: localStorage.getItem('userDetails')
    });
  }

  refreshData() {
    this.getNav();
  }

  // to logOut
  logOutAdmin() {
    this.authService.adminLogout();
  }

  // to get the side nav from Database
  getNav() {
    this.sidebarService.getsideNav().subscribe(res => {
      this.dynamicNav = [];
      if (res && res.Status == 'Success') {
        if (res.data && res.data.length > 0) {
          this.dynamicNav = res.data;
        }
      }
    });
  }

  // for nav Icons
  getIconForNav(name, index) {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.iconUrl + (index + 1) + '.svg'));
    return name;
  }


  // to navigate
  navigateTo(navItem, index) {
    if ((['/members', '/enquiry', '/staff', '/accounts', '/branches']).includes(navItem.url)) {
      this.loaderType = 'custom';
    } else {
      this.loaderType = 'custom';
    }
    if (!navItem.children || !navItem.children.length) {
    }
    if (navItem.children && navItem.children.length > 0) {
      this.expanded = !this.expanded;
    }
    if (this.mobileQuery.matches) {
      this.snav.close();
    }
  }

  openGlobeSearchBox(event?: any) {
    if (!event) {
      return;
    }
    this.showSearch.isOpen = true;
    this.showSearch.boxClass = 'slideInDown';
  }

  onSearchCardAction(event?: any) {
    this.showSearch.boxClass = 'slideOutUp';
    setTimeout(() => {
      this.showSearch.isOpen = false;
      this.searchInput.phoneNumber = '';
    }, 1000);
  }

  // for key escape event binding 
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.onSearchCardAction();
  }

  showMyProfile() {
    this.router.navigate(['/profile']);
  }
}