import { NgModule } from "@angular/core";

import { GlobeSearchComponent } from "./globe/globe.component";
import { GlobeSearchService } from "./globe-search.service";
import { AngularMaterialModule } from "src/app/shared-modules/angular.material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [AngularMaterialModule, ReactiveFormsModule, FormsModule],
  exports: [GlobeSearchComponent],
  declarations: [GlobeSearchComponent],
  providers: [GlobeSearchService],
})
export class GlobeSearchModule {}
