import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagePopupComponent } from './package-popup.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';



@NgModule({
  declarations: [
    PackagePopupComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  exports:[PackagePopupComponent]
})
export class PackagePopupModule { }
