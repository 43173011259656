import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SwPush } from "@angular/service-worker";
import { GlobalApiService } from '../global-api.service';
import { Observable } from 'rxjs';

// this is for demo now
@Injectable()
export class PushNotificationService {
  public permission: Permission;
  constructor(private http: HttpClient,
    private swPush: SwPush,
    private globalService: GlobalApiService) {
    this.requestPermission();
  }
  public isSupported(): boolean {
    return 'Notification' in window;
  }
  requestPermission(): void {
    if ('Notification' in window) {
      Notification.requestPermission((status) => {
        this.permission = status;
      });
    }
  }
  addPushSubscriber() {
    this.swPush.requestSubscription({
      serverPublicKey: this.globalService.VAPID_PUBLIC_KEY
    })
      .then(sub => {

        this.http.post(this.globalService.VAPID_PRIVATE_KEY + '/api/notifications', sub).subscribe(
          () => console.log('Subscription added successfully.'),
          err => console.error('Could not send subscription object to server, reason: ', err)
        );
      })
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  send() {
    this.http.post(this.globalService.VAPID_PRIVATE_KEY + '/api/notifications/enable', null).subscribe(
      () => console.log('Notification send successfully!'),
      err => console.error('Failed, reason: ', err)
    );
  }
  create(title: string, options?: PushNotification): any {
    const self = this;
    return new Observable((obs) => {
      if (!('Notification' in window)) {
        obs.complete();
      }
      if (self.permission === 'granted') {
        const _notify = new Notification(title, options);
        _notify.onshow = function (e) {
          return obs.next({
            notification: _notify,
            event: e
          });
        };
        _notify.onclick = function (e) {
          return obs.next({
            notification: _notify,
            event: e
          });
        };
        _notify.onerror = function (e) {
          return obs.error({
            notification: _notify,
            event: e
          });
        };
        _notify.onclose = function () {
          return obs.complete();
        };
      }
    });
  }
}

export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: 'auto' | 'ltr' | 'rtl';
  lang?: string;
  vibrate?: number[];
  requireInteraction?: boolean;
}
