import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { NewCalendarService } from '../new-calendar.service';

import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import { Observable } from 'rxjs';
import { colors } from '../demo-utils/colors';

interface eventInterface {
  id: number;
  title: string;
  date: string;
  color: string;
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}
@Component({
  // selector: 'fyt-new-calendar',
  templateUrl: './new-calendar.component.html',
  styleUrls: ['./new-calendar.component.css'],
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // templateUrl: 'template.html',
})
export class NewCalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events$: Observable<CalendarEvent<{ events: eventInterface }>[]>;
  eventsArray: []
  activeDayIsOpen: boolean = false;

  constructor(
    private calendarService: NewCalendarService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    let postObj = {
      startDate: getStart(this.viewDate),
      endate: getEnd(this.viewDate)
    }

    this.events$ = this.calendarService.getCalendarEvent(postObj).pipe(
      map(({ data }: { data: eventInterface[] }) => {
        return data.map((events: eventInterface) => {
          let retutnData = {
            title: events.title,
            start: new Date(
              events.date 
            ),
            color: events.color ? colors[events.color] : colors.yellow,
            allDay: true,
            meta: {
              events,
            },
          }
          console.log("retutnData------", retutnData);
          return retutnData;
        });
      })
    )
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ events: eventInterface }>[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent<{ events: eventInterface }>): void {
    window.open(
      `https://www.themoviedb.org/movie/${event.meta.events.id}`,
      '_blank'
    );
  }

}
