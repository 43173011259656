import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminBlogService {

  blogData: any;

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService
  ) { }

  getBlogs(): Observable<any> {
    return this.http.get('https://api.fytrack.com/blog/get?blogId=64d48ff379486797c50ff864')
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
  
}
